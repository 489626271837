import React from 'react';
import styled from '@emotion/styled';

import { Typography } from '@/components/atoms';
import { ProductThumbnail } from '@/components/molecules';
import ImageDataType from '@/types/data/image';
import mq from '@/styles/mq';


interface CategoriesProductsProps {
    categoryTitle: string;
    products: {
        uid: string;
        title: string;
        image: ImageDataType;
    }[] | undefined;
}

const CategoriesProductsRoot = styled.section`
    padding-top: ${({ theme }) => theme.spacing(1)};
    padding-left: ${({ theme }) => theme.spacing(1)};
    padding-right: ${({ theme }) => theme.spacing(1)};
    padding-bottom: ${({ theme }) => theme.spacing(1)};
    ${mq('xs')} {
        padding-top: ${({ theme }) => theme.spacing(3)};
        padding-left: ${({ theme }) => theme.spacing(3)};
        padding-right: ${({ theme }) => theme.spacing(3)};
        padding-bottom: ${({ theme }) => theme.spacing(3)};
    }
    ${mq('md')} {
        padding-top: ${({ theme }) => theme.spacing(5)};
        padding-left: ${({ theme }) => theme.spacing(5)};
        padding-right: ${({ theme }) => theme.spacing(5)};
        padding-bottom: ${({ theme }) => theme.spacing(5)};
    }
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const ProductsContainer = styled.section`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
`;

const CategoryTitle = styled(Typography)`
    color: ${({ theme }) => theme.color.primary.main};
    margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

const CategoriesProducts = ({ categoryTitle, products }: CategoriesProductsProps): JSX.Element => {

    return (
        <CategoriesProductsRoot>
            <CategoryTitle variant="h1" as="h1">
                {categoryTitle}
            </CategoryTitle>
            <ProductsContainer>
                {products?.map((product, i) => (
                    <ProductThumbnail key={i} {...product} />
                ))}
            </ProductsContainer>
        </CategoriesProductsRoot>
    );
};

export default CategoriesProducts;