import React from 'react';
import styled from '@emotion/styled';

import { ContactBox } from '@/components/molecules';
import { PrismicCategory } from '@/domain/PrismicCategory';
import CategoriesProducts from '@/containers/Categories/CategoriesProducts';

interface CategoriesContainerProps {
    data: {
        prismicCa: PrismicCategory;
    };
    lang: string;
}

const CategoriesContainerRoot = styled.section``;

const CategoriesContainer = ({ data, lang }: CategoriesContainerProps): JSX.Element => {
    const { products, category_title } = data?.prismicCa?.data;
    
    const Products = products?.map(product => ({
        uid: product?.product?.document?.uid,
        title: product?.product?.document?.data?.product_title?.text,
        image: product?.product?.document?.data?.product_gallery[0]?.product_image,
    }))

    return (
        <CategoriesContainerRoot>
            <CategoriesProducts categoryTitle={category_title?.text} products={Products} />
            <ContactBox lang={lang} />
        </CategoriesContainerRoot>
    );
};

export default CategoriesContainer;