import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from '@/components/atoms';
import { Layout } from '@/components/organisms';

import CategoriesContainer from '@/containers/Categories/CategoriesContainer';
import { PrismicCategory } from '@/domain/PrismicCategory';
import { PageContext } from '@/domain/PageContext';

interface CategoriesTemplateProps {
  data: {
    prismicCa: PrismicCategory;
    allPrismicCataloguePage: {
      edges: {
        node: {
          uid: string;
          lang: string;
        };
      }[];
    };
  };
  pageContext: PageContext;
}

export const query = graphql`
  query ($id: String!) {
    prismicCa(id: { eq: $id }) {
      id
      lang
      uid
      alternate_languages {
        lang
        uid
      }
      data {
        category_title {
          text
        }
        products {
          product {
            document {
              ... on PrismicProduct {
                id
                uid
                lang
                data {
                  product_title {
                    text
                  }
                  product_gallery {
                    product_image {
                      alt
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const CategoriesTemplate = ({
  data,
  pageContext,
}: CategoriesTemplateProps): JSX.Element => {
  const { finalAlternateCategories, alternateLinkCat } = pageContext;
  const { lang, uid, alternate_languages } = data?.prismicCa;
  const title = data?.prismicCa?.data?.category_title?.text;

  const selectlang = {
    lang,
    uid: uid,
    alternate_languages: finalAlternateCategories,
  };
  const seo = {
    title: title,
    description: title,
    lang: lang,
    url: `${lang}`,
  };
  const linkAlternate = alternateLinkCat?.map((data) => ({
    lang: data?.lang,
    url: `${data?.lang}/${data?.uid}`,
  }));

  return (
    <Layout selectLang={selectlang}>
      <SEO
        lang={seo.lang}
        title={`${seo.title}`}
        url={seo.url}
        description={seo.description}
        alternate={linkAlternate}
      />
      <CategoriesContainer data={data} lang={lang} />
    </Layout>
  );
};

export default CategoriesTemplate;
